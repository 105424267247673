<template>
  <div class="certificate w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('棋力證書')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>

    <loading-wrapper v-if="isPageLoading"></loading-wrapper>

    <EmptyPlaceholder
      v-if="!isLoading && totalScores.length === 0"
      icon="Certificate"
      :text="$t('目前沒有棋力證書')"
      :sub-text="$t('棋力檢定通過後證書將會顯示在這')"
    />

    <div v-else class="carousel-wrapper scroll-bar">
      <div class="web-card">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="0"
          indicators
          no-wrap
        >
          <b-carousel-slide v-for="item in totalScores" :key="item.id">
            <template #img>
              <div
                class="whole-container d-flex align-items-center justify-content-center w-100 pt-3 pl-3 pr-3 p-md-0 position-relative"
              >
                <div class="shadow">
                  <certification
                    ref="certification"
                    :name="item.nickname || item.realname"
                    :rank="item.rank"
                    :time="timestamp(item.overTime)"
                  ></certification>
                </div>
              </div>
            </template>
          </b-carousel-slide>
          <div v-if="$device.isMobile" class="icon-wrapper position-absolute">
            <div
              class="postion-relative w-100 d-flex justify-content-center align-items-center pl-3 pr-3"
            >
              <div
                class="download-btn d-flex justify-content-center align-items-center position-absolute cursor-pointer bg-primary"
                @click="downloadCertification()"
              >
                <i class="icon-Download text-white"></i>
              </div>

              <div
                class="slide-btn-wrapper d-flex justify-content-center align-items-center"
              >
                <i
                  class="left icon-Chevron-left arrow"
                  :class="{disable: isFirstSlide}"
                  @click="prevSlide()"
                ></i>
                <span
                  class="slide-text text-16 d-flex justify-content-center align-items-center"
                >
                  {{ slide + 1 }}/{{ totalScores.length }}
                </span>
                <i
                  class="right icon-Chevron-right arrow"
                  :class="{disable: isLastSlide}"
                  @click="nextSlide()"
                ></i>
              </div>
              <div
                class="share-btn icon-circle d-flex justify-content-center align-items-center position-absolute cursor-pointer bg-primary"
                @click="shareCertification()"
              >
                <i class="icon-Share text-white"></i>
              </div>
            </div>
          </div>
        </b-carousel>
        <div
          v-if="!$device.isMobile"
          class="icon-wrapper mx-auto"
          style="width: 461px"
        >
          <div
            class="postion-relative w-100 d-flex justify-content-between align-items-center"
          >
            <div
              class="download-btn d-flex justify-content-center align-items-center cursor-pointer bg-primary"
              @click="downloadCertification()"
            >
              <i class="icon-Download text-white"></i>
            </div>
            <div
              class="slide-btn-wrapper d-flex justify-content-center align-items-center"
            >
              <span
                class="slide-text text-16 d-flex justify-content-center align-items-center"
              >
                {{ slide + 1 }}/{{ totalScores.length }}
              </span>
            </div>
            <div class="download-btn bg-white"></div>
          </div>
        </div>
        <i
          v-if="!$device.isMobile"
          class="left icon-Chevron-left arrow position-absolute cursor-pointer"
          :class="{disable: isFirstSlide}"
          @click="prevSlide()"
        ></i>
        <i
          v-if="!$device.isMobile"
          class="right icon-Chevron-right arrow position-absolute cursor-pointer"
          :class="{disable: isLastSlide}"
          @click="nextSlide()"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Certification from '@/components/Personal/Certification.vue';
import html2canvas from 'html2canvas';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import savePhoto from '@/lib/base/savePhoto.js';

export default {
  components: {
    BaseHeader,
    Certification,
    EmptyPlaceholder,
    LoadingWrapper,
  },
  data() {
    return {
      slide: 0,
      isPageLoading: false,
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('棋力證書'),
          enable: true,
          router: 'certificate',
        },
      ],
    };
  },
  computed: {
    totalScores() {
      const initialData =
        this.$store.getters['verification/totalScores']?.filter(
          (score) => score.realname
        ) || [];
      const sortData = initialData.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      return sortData;
    },
    isFirstSlide() {
      return this.slide === 0;
    },
    isLastSlide() {
      return this.slide === this.totalScores.length - 1;
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
  },
  created() {
    this.getTotalScores();
  },
  methods: {
    async getTotalScores() {
      try {
        this.isPageLoading = true;
        await this.$store.dispatch('verification/getTotalScores');
      } finally {
        this.isPageLoading = false;
      }
    },
    timestamp(time) {
      return this.$day(time).format('YYYY.MM.DD');
    },
    prevSlide() {
      if (this.isFirstSlide) {
        return;
      } else {
        this.slide -= 1;
      }
    },
    nextSlide() {
      if (this.isLastSlide) {
        return;
      } else {
        this.slide += 1;
      }
    },
    async covert2Base64(dom) {
      const canvas = await html2canvas(dom);

      const base64 = canvas.toDataURL();
      return base64;
    },
    async downloadCertification() {
      this.$store.commit('env/setIsLoading', true);
      try {
        const dom = this.$refs.certification[this.slide].$el;
        const base64 = await this.covert2Base64(dom);

        savePhoto(base64, `certificate-${new Date().getTime()}.png`);

        this.$store.commit('env/setIsLoading', false);
        this.$Message.success(this.$t('證書儲存成功'), 3000);
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.$Message.error(this.$t('證書儲存失敗'), 3000);
      }
    },
    async shareCertification() {
      this.$store.commit('env/setIsLoading', true);
      const dom = this.$refs.certification[this.slide].$el;
      const base64 = await this.covert2Base64(dom);

      setTimeout(() => {
        this.$store.commit('env/setIsLoading', false);
      }, 2000);
      await this.$JShare.shareImage(base64);
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  min-height: calc(100% - 56px);
  @media screen and (min-width: 768px) {
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
  }

  .carousel-wrapper {
    overflow: overlay;
    @media screen and (min-width: 768px) {
      width: 100%;
      height: calc(100vh - 80px);
      overflow: overlay;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }
    .web-card {
      @media screen and (min-width: 768px) {
        background: white;
        box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
        border-radius: 10px;
        padding: 24px 16px;
        max-width: 720px;
        width: 100%;
        margin: 40px auto;
        position: relative;
      }
      @media screen and (min-width: 1200px) {
        max-width: 1140px;
      }
    }
    .whole-container {
      margin-bottom: 20px;
      .shadow {
        box-shadow: 5px 10px 5px 0 rgba(0, 0, 0, 0.1) !important;
      }
    }
    /deep/ .carousel-indicators {
      display: none;
    }
    .carousel-item {
      width: 100vw;
      height: calc((100vw - 32px) * 1.5 + 62px);
      @media screen and (min-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }
    .icon-wrapper {
      width: 100%;
      margin-top: 10px;
      top: 140vw;
      font-size: 16px;
      @media screen and (min-width: 768px) {
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        top: 716px;
      }
      .download-btn {
        left: 16px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        i {
          font-size: 28px;
        }
      }
      .share-btn {
        right: 16px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: $secondary;
        @media screen and (min-width: 768px) {
          background-color: $primary;
        }
        i {
          font-size: 28px;
        }
      }
      .slide-btn-wrapper {
        i {
          font-size: 32px;
          @media screen and (min-width: 768px) {
            font-size: 48px;
          }
        }
        .slide-text {
          width: 36px;
          height: 28px;
          margin: 0 14px;
          font-family: 'Helvetica Neue' !important;
        }
      }
    }
  }
  .left {
    top: 350px;
    left: calc((100% - 587px) / 2);
    font-size: 48px;
  }
  .right {
    top: 350px;
    right: calc((100% - 587px) / 2);
    font-size: 48px;
  }
  .arrow {
    color: $primary;
    &.disable {
      color: rgba($color: $primary, $alpha: 0.5);
    }
  }
}
</style>
